<template>
  <v-card class="ma-5">
    <v-card-title>
      カメラ情報
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        text="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="camera"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
        headers: [
          { text: 'ID',
            value: 'id',
            align: 'start',
          },
          {text: 'カメラID', value: 'camera_id'},
          {text: '設置場所', value: 'address'},
          {text: '緯度', value: 'latitude'},
          {text: '経度', value: 'longitude'},
        ],
        camera: []
      }
    },
    mounted(){
      this.$axios({
        method  : 'GET',
        url     : 'https://ai-camera.itkobo-z.jp/api/cameras/',
        auth    : { username : 'itkoboz', password : 'HmtlyY6V' },
      }).then(response => this.camera = response.data)
    }
  }
</script>

<style>
</style>