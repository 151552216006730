import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'
import Camera from '@/views/Camera'
import Data from '@/views/Data'
import Login from '@/views/Login'
import Home from '@/views/Home'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Index,
        meta: {requiresAuth: true},
        children: [
            {
                path: '/',
                component: Home
            },
            {
                path: '/Camera',
                component: Camera
            },
            {
                path: '/Data',
                component: Data
            }
        ]
    },
    {
        path: '/login',
        component: Login
    }
]

const router =  new VueRouter({
    mode: "history",
    routes: routes
})

router.beforeEach((to, from, next) => {
    const isLoggedin = store.state.auth.login.token &&
    store.state.auth.login.expire > (new Date()).getTime()
  if ( to.path === "/login"){
      next()
  } else {
      isLoggedin ? next():
      next({path: '/login', query: {backuri: to.fullPath}})
  }
})

export default router