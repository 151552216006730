<template>
  <v-app id="inspire">
    <v-navigation-drawer
     v-model="drawer"
     app
    >
      <v-list-item class="teal lighten-3">
        <v-list-item-content>
          <v-list-item-title class="title text-center">
            Controll
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="info" @click="logout" block>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#fcb69f"
      dark
      shrink-on-scroll
      src="img/scarecrow.jpg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to bottom right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          position="25% 75%"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>AI-Camera</v-app-bar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view>
      </router-view>
      <!--  -->
    </v-main>
    <v-footer padless>
      <v-col
        class="text-center teal lighten-3"
        cols="12"
        >
        {{ new Date().getFullYear() }} — <strong>itkobo-z</strong>
      </v-col>
  </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
       drawer: null,
       items: [
         { title: 'Cameras', icon: 'mdi-camera', to: '/Camera'},
         { title: 'Data', icon: 'mdi-database', to: '/Data'},
       ],
    }),
    methods: {
    logout() {
      this.$store.dispatch("deleteLoginInfo")
      this.$router.push("/login");
    }
  }
  }
</script>