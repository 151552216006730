const auth = {
  state: {
    login: {
      token: "",
      expire: 0
    }
  },
  mutations: {
    SET_LOGIN_INFO: (state, login) => {
      state.login.token  = login.access_token

      state.login.expire = new Date().getTime() + (7*24*60*60*1000)
    },
    DELETE_LOGIN_INFO: (state) => {
      state.login.token = "",
      state.login.expire = 0
    }
  },
  actions: {
    setLoginInfo({ commit }, login) {
      commit("SET_LOGIN_INFO", login)
    },
    deleteLoginInfo({ commit }) {
      commit("DELETE_LOGIN_INFO")
    }
  }
}

export default auth