<template>
  <v-app>
    <v-card width="400px" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">ログイン</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field 
            prepend-icon="mdi-account-circle" 
            label="ユーザ名" 
            v-model="user.id" 
          />
          <v-text-field 
            v-bind:type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            prepend-icon="mdi-lock" 
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            v-model="user.password"
          />   
          <v-card-actions>
             <v-btn class="info" @click="login">ログイン</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    showPassword: false,
    loading: false,
    user: {
      id: "",
      password: ""
    }
    
  }),
  methods: {
    login() {
      this.loading = true
      // APIでログイン認証を行う
      this.$axios({
          method  : 'POST',
          url     : 'https://ai-camera.itkobo-z.jp/api/auth/login',
          auth    : { username : 'itkoboz', password : 'HmtlyY6V' },
          data    : {id: this.user.id, password: this.user.password}
      }).then(res => {
        // 成功した場合
        if (res.status == 200) {
          console.log(res.data)
          // ログイン情報を store に保存
          this.$store.dispatch("setLoginInfo", res.data)
          // 元の画面に戻る
          this.$router.push({path: "backuri" in this.$route.query && this.$route.query.backuri.match(/^\//) ? this.$route.query.backuri : '/'})
        // メールアドレスとパスワードが正しくない組み合わせだった場合
        } else {
          this.loading = false
          alert("ログインに失敗しました。")
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>