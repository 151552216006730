<template>
  <v-card class="ma-5">
    <v-card-title>
      認識データ
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        text="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
        headers: [
          { text: 'カメラID',
            value: 'camera_id',
            align: 'start',
          },
          {text: '種類', value: 'bird_type'},
          {text: '個体数', value: 'bird_num'},
          {text: '認識率(%)', value: 'recognition_rate'},
          {text: '認識日時', value: 'datetime'},
        ],
        data: []
      }
    },
    mounted(){
      this.$axios({
        method  : 'GET',
        url     : 'https://ai-camera.itkobo-z.jp/api/data/',
        auth    : { username : 'itkoboz', password : 'HmtlyY6V' },
      }).then(response => this.data = response.data)
    }
  }
</script>

<style>
</style>